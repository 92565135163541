@import "onetrust-banner.css";

html,
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

#__next {
  position: relative;
  background-color: #ffffff;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: "Arial Black";
  font-style: normal;
  font-weight: 400;
  src: local("Arial Black"),
    url(https://fonts.gstatic.com/l/font?kit=8AttGsyyIJeeQk-K7cT5V6nUqRryBTos&skey=f5c444da86865f17&v=v11)
      format("woff2");
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Primark Basis";
  src: url("/assets/fonts/PrimarkBasis-Regular.woff2") format("woff2"),
    url("/assets/fonts/PrimarkBasis-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Primark Basis";
  src: url("/assets/fonts/PrimarkBasis-Bold.woff2") format("woff2"),
    url("/assets/fonts/PrimarkBasis-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
